<template>
  <div class="help">
    <svg
      width="2.5rem"
      height="1.5rem"
      viewBox="0 0 39 24"
      fill="none"
      class="arrow"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8896 1.68666L19.5 1.20214L19.1104 1.68666L2.22286 22.6867L1.56881 23.5H2.6125H36.3875H37.4312L36.7771 22.6867L19.8896 1.68666Z"
        stroke="#18214D"
        fill="white"
      />
    </svg>

    <div class="help-title">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
          fill="#377DFF"
        />
        <path d="M9.5 6H12.5L12 12H10L9.5 6Z" fill="#377DFF" />
        <path
          d="M12.5 14.5C12.5 15.3284 11.8284 16 11 16C10.1716 16 9.5 15.3284 9.5 14.5C9.5 13.6716 10.1716 13 11 13C11.8284 13 12.5 13.6716 12.5 14.5Z"
          fill="#377DFF"
        />
      </svg>
      <span>{{ $t('important') }}</span>
    </div>
    <slot />
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
.help {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  bottom: 5rem;
  border: 0.09rem solid var(--dark-blue);
  padding: 1.25rem;
  max-width: 20rem;
  width: 100%;
  background: var(--white);
  transition: all 0.3s ease;

  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  opacity: 0;
  pointer-events: none;

  &.activeHelp {
    opacity: 1;
  }

  img {
    width: 100%;
    height: auto;
    margin: 0.63rem 0;
  }

  color: #5c6077;
  .arrow {
    position: absolute;
    bottom: -1.5rem;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
  }

  &-title {
    display: flex;
    align-items: center;
    margin-bottom: 0.63rem;

    span {
      font-weight: 700;
      font-size: 1.13rem;
      line-height: 1.5rem;
      color: var(--dark-blue);
      margin-left: 0.5rem;
    }
  }
}
</style>
