<template>
  <AuthWrap :title="$t('authPage.title')" :text="$t('authPage.subTitle')">
    <div class="container-auth__form">
      <form @submit.prevent="enterCabinet">
        <div class="relative">
          <PrimaryInput
            style="margin-bottom: 1.875rem"
            :placeholderText="$t('register.login')"
            :errText="errInput ? userErr.id_doc + ' ' : ''"
            :success="user.id_doc.length != 0 && !errInput"
          >
            <input
              v-model="id_doc"
              @input="inputID(id_doc)"
              @blur="activeHelp = false"
              @focus="
                () => {
                  activeHelp = true;
                }
              "
              :placeholder="$t('register.login')"
              type="text"
              maxlength="9"
              :class="{ 'error-border': errInput }"
            />
          </PrimaryInput>
          <HelpWrap :class="{ activeHelp }">
            <img :src="require(`@/assets/img/auth.png`)" alt="Passport" />
            <p class="no-valid">{{ userErr.id_doc }}</p>
          </HelpWrap>
        </div>
        <PrimaryInput
          style="margin-bottom: 1.875rem"
          :placeholderText="$t('authPage.password')"
          :errText="errInput ? userErr.password + ' ' : ''"
          :success="user.password.length != 0 && !errInput"
        >
          <input
            v-model="password"
            :placeholder="$t('authPage.password')"
            type="current-password"
          />
        </PrimaryInput>

        <div class="bottom-form">
          <div>
            <span>{{ $t("authPage.forgotPassword") }}</span>
            <router-link
              class="link"
              :to="$i18nRoute({ name: 'ResetPassword' })"
              >{{ $t("authPage.resetPassword") }}
            </router-link>
          </div>
          <div>
            <span>{{ $t("authPage.noAccount") }}</span>
            <router-link class="link" :to="$i18nRoute({ name: 'Register' })"
              >{{ $t("header.register") }}
            </router-link>
          </div>
        </div>
        <PrimaryButton :disabled="disabled" :title="$t('buttons.enter')" />
        <!-- <button :disabled="disabled" class="gradient-btn next-btn">
          {{ $t("buttons.enter") }}
        </button> -->
      </form>
      <p class="no-valid" v-if="errorMsg">{{ errorMsg }}</p>
    </div>
  </AuthWrap>
  <ModalContainer v-if="showModal">
    <div class="modal">
      <div class="title primary-text">
        {{ $t("NewRegister.LogoutOtherGadget") }}
      </div>
      <PrimaryLink :title="$t('registrationExem.home')" routeName="Home" />
    </div>
  </ModalContainer>
  <transition name="fade">
    <ModalContainer v-show="isRegistered">
      <div class="re-loaded-content">
        <h1 class="title primary-title">{{ $t('register.success_title') }}</h1>
        <p class="description primary-text">{{ $t('register.success') }}</p>
        <PrimaryButton @click="replaceRoute" :title="$t('buttons.thanks')" />
      </div>
    </ModalContainer>
  </transition>
</template>

<script>
import { reactive, ref, toRefs, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useLanguage } from "@/utils/language";
import { useStore } from "vuex";
import ModalContainer from "@/components/ModalContainer.vue";
import PrimaryInput from "@/components/PrimaryInput.vue";
import AuthWrap from "@/components/AuthWrap.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import PrimaryLink from "../components/PrimaryLink.vue";
import HelpWrap from "../components/HelpWrap.vue";

export default {
  components: {
    PrimaryButton,
    ModalContainer,
    PrimaryInput,
    AuthWrap,
    PrimaryLink,
    HelpWrap,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { i18nRoute } = useLanguage();
    const disabled = ref(false);
    const showModal = ref(false);
    const activeHelp = ref(false);
    const isRegistered = ref(false);

    const replaceRoute = () => {
      isRegistered.value = false;
      router.replace(
        i18nRoute({
          name: "AccountInfo",
        })
      );
    };

    watch(
      () => route.query,
      () => {
        if (route.query.registered) {
          isRegistered.value = true;
        }
      },
      { immediate: true }
    );

    const user = reactive({
      id_doc: "",
      password: "",
    });

    const userErr = reactive({
      id_doc: "",
      password: "",
    });

    const errorMsg = ref("");
    const errInput = ref(false);

    const enterCabinet = () => {
      disabled.value = true;
      errInput.value = false;
      userErr.id_doc = "";
      userErr.password = "";

      store.dispatch("auth/getToken", {
        cb: async (res) => {
          const data = await res;
          disabled.value = false;
          if (data.error) {
            showModal.value = true;
            return;
          }
          if (route.query.next) {
            return router.push({ name: route.query.next });
          }
          router.push(
            i18nRoute({
              name: "AccountInfo",
            })
          );
        },
        user: { ...user },
        err: async (err) => {
          disabled.value = false;
          const error = await err;
          errorMsg.value = error.detail;
          errInput.value = true;
          userErr.id_doc = error.id_doc != null ? error.id_doc[0] : "";
          userErr.password = error.password != null ? error.password[0] : "";
        },
      });
    };
    const inputID = (val) => {
      if (val) {
        errInput.value = false;
        userErr.id_doc = "";
      }
      user.id_doc = val.toUpperCase();
    };
    return {
      inputID,
      errInput,
      enterCabinet,
      ...toRefs(user),
      errorMsg,
      userErr,
      user,
      showModal,
      isRegistered,
      activeHelp,
      disabled,
      replaceRoute,
    };
  },
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}

.no-valid {
  margin-top: 0.625rem;
  font-size: 1rem !important;
}
.relative {
  position: relative;
  .triangle {
    position: absolute;
    // transform: rotate(-90deg);
    // bottom: -15px;
    top: 10px;
    left: -15px;
  }
}
.container-auth {
  font-size: 16px;

  max-width: var(--container-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(11, 1fr var(--gap)) 1fr;

  .prompt {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background: linear-gradient(
      90deg,
      rgba(69, 3, 98, 0.5) 0%,
      rgba(111, 101, 235, 0.5) 0.01%,
      rgba(51, 4, 92, 0.5) 91.67%,
      rgba(75, 0, 130, 0.5) 100%
    );
    backdrop-filter: blur(13.6225px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 10.2169px;
  }
  .input-container {
    width: 75%;
  }
  .mt-2 {
    margin-top: 2em;
  }
  p,
  label,
  select {
    font-size: 1.3125em;
  }
  h2 {
    font-size: 2.2em;
    line-height: 2;
    font-weight: 500;
  }
  .sub-title {
    color: var(--gray-font-color);
    // white-space: nowrap;
    margin-bottom: 1.25em;
  }
  &__img {
    position: relative;
    grid-column: 1/10;
    margin-top: 5em;

    img {
      background: transparent;
      position: absolute;
      right: 0;
      top: 50%;
      width: 80%;
      transform: translateY(-50%);
    }
  }
  &__form {
    grid-column: 11/23;
    position: relative;
    .bottom-form {
      font-size: 1em;
      font-weight: 500;
      color: var(--disabled-color);
      div {
        margin-bottom: 0.3125rem;
        &:last-child {
          margin-bottom: 1.875rem;
        }
        .link {
          color: var(--primary-blue);
          margin-left: 0.6em;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .container-auth {
    max-width: 100%;
    display: flex;
    flex-direction: column-reverse;
    padding-top: 5rem;
    .input-container {
      width: 100%;
    }
    &__img {
      position: relative;
      max-width: 500px;

      margin: 0 auto;
      .img {
        position: static;
        width: 100%;
        height: auto;
      }
    }
    &__form {
      max-width: 550px;
      margin: 0 auto;
    }
  }
}
.modal {
  background: #fff;
  padding: 2em;
  font-size: 1.375em;
  text-align: center;
  max-width: 26.88rem;
  margin: 0 auto;
  border-radius: 0.63rem;

  .title {
    text-align: center;
    font-weight: 500;
    margin-bottom: 2em;
  }
}
@media screen and (max-width: 767px) {
  .container-auth {
    max-width: 90%;
    font-size: 12px;
    padding-top: 0;
    &__img {
      display: none;
      position: relative;
      // width: 500px;

      margin: 0 auto;
      .img {
        position: static;
        width: 100%;
        height: auto;
      }
    }
    &__form {
      // width: 550px;
      margin: 0 auto;
    }
  }
  .relative {
    position: relative;
    .triangle {
      position: absolute;
      bottom: -17px;
      top: auto;
      left: 50%;
      transform: translateX(-50%) rotate(-90deg);
      z-index: 0;
    }
  }
}
</style>
